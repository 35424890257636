body{
    color: white;
    background: #110829;
    min-height: 100vh;
    background-color: 'rgba(0, 0, 0, 0.9)';
    text-align: center;
    position: sticky;
    z-index: 1;
    overflow: clip;
    background-position: center; /* Center the image */
    background-repeat: repeat; 
    background-size: cover; /* Resize the background image to cover the entire container */
}

  
body h1{
    font-size: 3rem;
    margin: 0 0 1rem;
}

body h2{
    font-weight: 300;
    font-size: 1.5rem;
    margin: 0 0 1rem;
}

body a{
    color: #ffff;
}

.formLabel {
   
    font-size: 28px;
    width: 80px;
}

.form {
    font-size: 20px;
}



.logo {
  width: 360px; 
  height: 360px; 
  object-fit: contain; 
  object-position: center center;
}

.container {
  height: 100%;
  background-color: #0d1116;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.body-container {
  padding-top: 30px;
}

.body {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
}

.sub-text {
  font-size: 25px;
  color: white;
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connect-wallet-button {
  background: -webkit-linear-gradient(left, #60c657, #35aee2);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button {
  background: -webkit-linear-gradient(left, #a200d6, #ff6fdf);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-right: 15px;
}

.opensea-button {
  background-color: rgb(32, 129, 226);
}

.mint-count {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  
}

.twitter-logo {
  width: 35px;
  height: 35px;
}


/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}